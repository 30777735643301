import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './global/polyfills'
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import fastdom from 'fastdom'
import fastdomPromise from 'fastdom/extensions/fastdom-promised'
import Highway from '@dogstudio/highway'
import gsap from 'gsap'

import setupHeader from './partials/setupHeader'
import setupNavigation from './partials/setupNavigation'
import setupCanvas from './partials/setupCanvas'
import defaultRenderer from './router/defaultRenderer'
import frontTransition from './router/frontTransition'
import defaultTransition from './router/defaultTransition'
import emitter from './global/emitter'
import events from './global/events'

const _fastdom = fastdom.extend(fastdomPromise)

;(async () => {
  await setupHeader()
  await setupNavigation()
  await setupCanvas()

  const isSection =
    ['/', '/program', '/program/', '/en/'].indexOf(window.location.pathname) >
    -1

  const H = new Highway.Core({
    renderers: {
      front: defaultRenderer,
      default: defaultRenderer,
    },
    transitions: {
      front: frontTransition,
      default: defaultTransition,
    },
  })

  if (document.querySelector('#wpadminbar')) {
    H.detach(document.querySelectorAll('#wpadminbar a'))
  }

  H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    emitter.emit(events.NAVIGATE_OUT)
    emitter.emit(events.CANVAS_OUT)
  })

  H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
    emitter.emit(events.NAVIGATE_IN)

    if (
      ['/', '/program', '/program/', '/en/'].indexOf(location.pathname) > -1
    ) {
      emitter.emit(events.CANVAS_IN)
    }
  })
  const mainWrapper = document.querySelector('.l-main')

  await _fastdom.mutate(() => {
    mainWrapper.style.overflowY = 'hidden'
  })

  if (isSection === true) {
    emitter.emit(events.CANVAS_IN)

    gsap.fromTo(
      document.querySelector('.l-content'),
      {
        opacity: 0,
        y: 250,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        delay: 1.2,
        ease: 'power2.inOut',
        clearProps: 'transform',
        onStart: () => {
          window.scrollTo(0, 0)
        },
        onComplete: () => {
          _fastdom.mutate(() => {
            mainWrapper.style.overflowY = ''
          })
        },
      }
    )
  } else {
    const timeline = gsap.timeline()

    timeline.to(document.querySelector('.l-content'), {
      opacity: 1,
      duration: 1,
    })

    if (document.querySelector('.c-cover')) {
      timeline.fromTo(
        document.querySelector('.c-cover__image'),
        {
          scale: 1.025,
        },
        {
          scale: 1,
          duration: 1,
          ease: 'power2.out',
          clearProps: 'transform',
          onComplete: () => {
            _fastdom.mutate(() => {
              mainWrapper.style.overflowY = ''
            })
          },
        },
        '-=0.8'
      )
    }
  }
})()
