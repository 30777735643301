import gsap from 'gsap'
import settings from '../global/settings'

const setupNotice = (el) => {
  const notice = {
    el: el,
    button: undefined,

    setup: function () {
      this.button = this.el.querySelector('.c-notice__close')
      this.button.addEventListener('click', this.close.bind(this))

      if (this.isCookieSet() === false) {
        this.show()
      }
    },

    isCookieSet: function () {
      return document.cookie.indexOf('plt-notice-hide') !== -1
    },

    setCookie: function () {
      let date = new Date()
      date.setDate(date.getDate() + 1)
      date.setHours(0, 0, 0, 0)

      console.log(date.toUTCString())

      document.cookie = `plt-notice-hide=true;expires=${date.toUTCString()};samesite=strict;path=/`
    },

    show: function () {
      gsap.fromTo(
        this.el,
        {
          opacity: 0,
          y: '50px',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          delay: 2,
          ease: settings.animation.ease,
          onComplete: () => {
            this.el.style.pointerEvents = 'all'
          },
        }
      )
    },

    close: function () {
      this.setCookie()

      gsap.to(this.el, {
        opacity: 0,
        y: '50px',
        duration: 0.4,
        ease: settings.animation.ease,
        onComplete: () => {
          this.el.style.pointerEvents = 'none'
        },
      })
    },

    init: function () {
      this.setup()
    },
  }

  notice.init()
}

export default setupNotice
