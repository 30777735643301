import gsap from 'gsap'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import settings from '../global/settings'
import emitter from '../global/emitter'
import events from '../global/events'

const _fastdom = fastdom.extend(fastdomPromised)

const setupNavigation = () => {
  return new Promise(resolve => {
    const navigation = {
      el: document.querySelector('.c-navigation'),
      header: document.querySelector('.c-header'),
      overlay: document.querySelector('.c-navigation-overlay'),
      openBtn: document.querySelector('.c-header__menu'),
      closeContainer: document.querySelector('.c-navigation__close'),
      closeTxt: document.querySelector('.c-navigation__close__text'),
      closeIcon: document.querySelector('.c-navigation__close__icon'),
      parentList: document.querySelector('.c-navigation__list'),
      parentItems: document.querySelectorAll(
        '.c-navigation [data-children="true"]'
      ),
      backButtons: document.querySelectorAll('.c-navigation__child__back'),
      isOpen: false,
      tl: undefined,
      childTl: undefined,
      scrollBarOffset: undefined,

      setup: async function() {
        await _fastdom.measure(() => {
          this.scrollBarOffset = window.innerWidth - document.body.scrollWidth
        })

        this.tl = gsap.timeline()

        this.tl.fromTo(
          this.el,
          { x: '100%' },
          { x: '0%', duration: 0.3, ease: settings.animation.ease }
        )

        this.tl.fromTo(
          this.closeContainer,
          { x: '-100%', opacity: 0 },
          { x: '0%', opacity: 1, duration: 0.3, ease: settings.animation.ease },
          '-=0.3'
        )

        this.tl.to(
          this.closeTxt,
          {
            x: '-60%',
            duration: 0.2,
            ease: settings.animation.ease,
          },
          '-=0.2'
        )

        this.tl.fromTo(
          this.closeIcon,
          {
            scale: 0.8,
            opacity: 0,
          },
          {
            scale: 1,
            opacity: 1,
            duration: 0.2,
            ease: settings.animation.ease,
          },
          '-=0.2'
        )

        this.tl.pause()
      },

      showChildren(e) {
        e.preventDefault()
        const child = this.el.querySelector(
          `[data-parent="${e.target.getAttribute('data-id')}"]`
        )

        this.childTl = gsap.timeline()

        this.childTl.to(this.parentList, {
          scale: 0.8,
          opacity: 0,
          duration: 0.2,
          ease: settings.animation.ease,
        })

        this.childTl.to(
          child,
          {
            x: '0%',
            opacity: 1,
            duration: 0.2,
            ease: settings.animation.ease,
          },
          '-=0.2'
        )

        this.childTl.set(child, {
          pointerEvents: 'all',
        })

        this.childTl.play()
      },

      hideChildren(e) {
        if (e) {
          e.preventDefault()
        }
        if (this.childTl !== undefined) {
          this.childTl.reverse()
        }
      },

      openNav: async function() {
        this.isOpen = true

        await _fastdom.mutate(() => {
          this.closeContainer.style.pointerEvents = 'all'
          this.overlay.style.pointerEvents = 'all'
          document.body.style.paddingRight = `${this.scrollBarOffset}px`
          this.header.style.width = `calc(100% - ${this.scrollBarOffset}px)`
          document.body.classList.add('is-locked')
        })

        disableBodyScroll(this.el)
        this.tl.play()
      },

      closeNav: function() {
        enableBodyScroll(this.el)

        window.requestAnimationFrame(async () => {
          this.isOpen = false

          await _fastdom.mutate(() => {
            this.closeContainer.style.pointerEvents = 'none'
            this.overlay.style.pointerEvents = 'none'
            document.body.style.paddingRight = ''
            this.header.style.width = '100%'
            document.body.classList.remove('is-locked')
          })

          this.hideChildren()
          this.tl.reverse()
        })
      },

      init: function(resolve) {
        this.setup()
        this.overlay.addEventListener('click', this.closeNav.bind(this), false)
        this.openBtn.addEventListener('click', this.openNav.bind(this), false)
        this.closeTxt.addEventListener('click', this.closeNav.bind(this), false)
        this.closeIcon.addEventListener(
          'click',
          this.closeNav.bind(this),
          false
        )

        this.parentItems.forEach(item => {
          item.addEventListener('click', this.showChildren.bind(this), false)
        })

        this.backButtons.forEach(item => {
          item.addEventListener('click', this.hideChildren.bind(this), false)
        })

        emitter.listen(events.NAVIGATE_OUT, this.closeNav.bind(this))

        resolve()
      },
    }

    navigation.init(resolve)
  })
}

export default setupNavigation
