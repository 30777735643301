import gsap from 'gsap'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import emitter from '../global/emitter'
import events from '../global/events'
import settings from '../global/settings'
import setupNotice from './setupNotice'

const _fastdom = fastdom.extend(fastdomPromised)

const setupHeader = () => {
  return new Promise((resolve) => {
    const header = {
      page: document.querySelector('.l-main'),
      header: document.querySelector('.c-header'),
      overlay: document.querySelector('.c-header-overlay'),
      timetable: document.querySelector('.c-timetable'),
      timetableLink: document.querySelector('.c-timetable__general__link'),
      buttons: document.querySelectorAll('.js-toggle'),
      notice: document.querySelector('.c-notice'),

      ticking: false,
      lastKnownPosition: 0,
      currentPosition: window.scrollY,
      headerHidden: false,
      timetableHidden: true,
      scrollBarOffset: null,

      disable: function () {
        gsap.set(this.header, {
          opacity: 0,
          y: '-100%',
        })
      },

      enable: function () {
        gsap.set(this.header, {
          opacity: 1,
          y: '-100%',
        })
      },

      moveUp: function () {
        this.headerHidden = true

        gsap.to(this.header, {
          y: '-100%',
          duration: 0.4,
        })
      },

      moveDown: function () {
        this.headerHidden = false

        gsap.to(this.header, {
          y: '0%',
          duration: 0.4,
        })
      },

      update: function () {
        const buffer = 25
        const offset = 50
        this.ticking = false

        if (
          this.currentPosition > buffer &&
          this.currentPosition > this.lastKnownPosition &&
          this.headerHidden === false
        ) {
          this.moveUp()
        }

        if (this.currentPosition > offset) {
          gsap.set(this.header, {
            backgroundColor: '#969595',
          })
        } else {
          gsap.set(this.header, {
            backgroundColor: 'transparent',
          })
        }

        if (this.currentPosition < this.lastKnownPosition) {
          this.moveDown()
        }

        this.lastKnownPosition = this.currentPosition
      },

      tick: function () {
        if (this.ticking === false) {
          window.requestAnimationFrame(this.update.bind(this))
        }

        this.ticking = true
      },

      onScroll: async function () {
        await _fastdom.measure(() => {
          this.currentPosition = window.scrollY || window.pageYOffset
        })
        this.tick()
      },

      showTimetable: async function () {
        let rect = null

        await _fastdom.measure(() => {
          rect = this.timetable.getBoundingClientRect()
        })

        await _fastdom.mutate(() => {
          document.body.classList.add('is-locked')
          this.overlay.style.pointerEvents = 'all'
          document.body.style.paddingRight = `${this.scrollBarOffset}px`
          this.header.style.width = `calc(100% - ${this.scrollBarOffset}px)`
          this.header.classList.add('is-open')
        })

        disableBodyScroll(this.timetable)
        this.timetableHidden = false

        gsap.to([this.page, this.header], {
          y: `${rect.height}`,
          duration: 0.4,
          ease: settings.animation.ease,
        })

        gsap.to(this.timetable, {
          y: '0%',
          duration: 0.4,
          ease: settings.animation.ease,
        })
      },

      hideTimetable: function () {
        enableBodyScroll(this.timetable)

        window.requestAnimationFrame(async () => {
          await _fastdom.mutate(() => {
            document.body.classList.remove('is-locked')
            this.overlay.style.pointerEvents = 'none'
            document.body.style.paddingRight = ''
            this.header.style.width = '100%'
            this.header.classList.remove('is-open')
          })

          this.timetableHidden = true

          gsap.to([this.page, this.header], {
            y: '0',
            duration: 0.4,
            ease: settings.animation.ease,
            onComplete: () => {
              gsap.set(this.page, {
                clearProps: 'all',
              })
            },
          })

          gsap.to(this.timetable, {
            y: '-101%',
            duration: 0.4,
            ease: settings.animation.ease,
          })
        })
      },

      toggleTimetable: function () {
        if (this.timetableHidden === false) {
          this.hideTimetable()
        } else {
          this.showTimetable()
        }
      },

      init: async function (resolve) {
        await _fastdom.measure(() => {
          this.scrollBarOffset = window.innerWidth - document.body.scrollWidth
        })

        await _fastdom.mutate(() => {
          this.header.style.marginRight = `${this.scrollBarOffset}px`
        })

        window.addEventListener('scroll', this.onScroll.bind(this), false)

        this.overlay.addEventListener(
          'click',
          this.hideTimetable.bind(this),
          false
        )

        this.buttons.forEach((button) => {
          button.addEventListener(
            'click',
            this.toggleTimetable.bind(this),
            false
          )
        })

        this.timetableLink.addEventListener(
          'click',
          this.hideTimetable.bind(this)
        )

        if (this.notice) {
          import(/* webpackChunkName: "notice" */ './setupNotice').then(
            ({ default: setupNotice }) => {
              setupNotice(this.notice)
            }
          )
        }

        emitter.listen(events.HEADER_DISABLE, this.disable.bind(this))
        emitter.listen(events.HEADER_ENABLE, this.enable.bind(this))

        resolve()
      },
    }

    header.init(resolve)
  })
}

export default setupHeader
