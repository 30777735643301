import Highway from '@dogstudio/highway'
import Page from '../partials/page'

class DefaultRenderer extends Highway.Renderer {
  onEnter() {
    this.page = Page(this.wrap.lastElementChild)
    this.page.init()
  }

  onLeaveCompleted() {
    this.page.destroy()
  }
}

export default DefaultRenderer
