export default (() => {
  if (!window.fetch) {
    import(/* webpackChunkName: "fetch" */ 'whatwg-fetch')
  }

  if (typeof window.CustomEvent !== 'function') {
    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: null }
      var evt = document.createEvent('CustomEvent')
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      )
      return evt
    }

    CustomEvent.prototype = window.Event.prototype

    window.CustomEvent = CustomEvent
  }

  if ('objectFit' in document.documentElement.style === false) {
    import(/* webpackChunkName: "object-fit" */ 'lazysizes/plugins/object-fit/ls.object-fit')
  }
})()
