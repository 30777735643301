import gsap from 'gsap'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import emitter from '../global/emitter'
import events from '../global/events'

const _fastdom = fastdom.extend(fastdomPromised)

const setupCanvas = () => {
  return new Promise(resolve => {
    const planet = {
      canvas: document.querySelector('.c-canvas'),
      ww: null,
      wh: null,
      cw: 0,
      ch: 0,
      pxRatio: window.devicePixelRatio,
      planetRadius: 0,
      scale: 0.75,
      alpha: 0,
      rotation: 40,
      step: 1,
      ctx: null,
      intervalId: null,
      gradient: null,
      colors: [
        ['#007537', '#F8C9DF'],
        ['#668AAE', '#14002D', '#B0C4D6'],
        ['#DE5A67', '#6A2BF9'],
        ['#5E4F9C', '#111111', '#EA5045'],
        ['#7425F2', '#000000'],
        ['#E54C41', '#790E00', '#CD1719'],
        ['#8FC1DB', '#142517'],
        ['#C6C6C6', '#000000', '#4E4D4D'],
      ],
      selectedColors: null,
      timeline: null,

      setup: function() {
        this.ctx = this.canvas.getContext('2d')
        this.setCanvasSize()
        this.setGradient()

        window.addEventListener('resize', this.onResize.bind(this), false)
        emitter.listen(events.CANVAS_OUT, this.outro.bind(this))
        emitter.listen(events.CANVAS_IN, this.intro.bind(this))
      },

      setGradient: function() {
        this.selectedColors = this.colors[
          Math.floor(Math.random() * this.colors.length)
        ]
      },

      setCanvasSize: async function() {
        await _fastdom.measure(() => {
          this.ww = window.innerWidth
          this.wh = window.innerHeight
        })

        this.cw = this.ww * this.pxRatio
        this.ch = this.wh * this.pxRatio

        await _fastdom.mutate(() => {
          this.canvas.width = this.cw
          this.canvas.height = this.ch
          this.canvas.style.width = `${this.ww}px`
          this.canvas.style.height = `${this.wh}px`
        })

        this.planetRadius =
          this.ww > this.wh ? this.ch / 2 - 48 : this.cw / 2 - 48
      },

      updateGradient: function() {
        this.gradient = this.ctx.createRadialGradient(
          0,
          (this.planetRadius - (this.planetRadius / 20) * this.step) * -1,
          0,
          0,
          (this.planetRadius - (this.planetRadius / 20) * this.step) * -1,
          this.planetRadius * 3
        )

        if (this.selectedColors.length === 3) {
          this.gradient.addColorStop(0, this.selectedColors[0])
          this.gradient.addColorStop(0.45, this.selectedColors[1])
          this.gradient.addColorStop(0.6325, this.selectedColors[2])
        } else {
          this.gradient.addColorStop(0, this.selectedColors[0])
          this.gradient.addColorStop(0.525, this.selectedColors[1])
        }
      },

      draw: function() {
        this.ctx.clearRect(
          Math.floor(this.cw / 2 - this.planetRadius - 20),
          Math.floor(this.ch / 2 - this.planetRadius - 20),
          Math.floor(this.planetRadius * 2 + 40),
          Math.floor(this.planetRadius * 2 + 40)
        )

        this.ctx.globalAlpha = this.alpha

        this.ctx.save()
        this.ctx.translate(this.cw / 2, this.ch / 2)
        this.ctx.rotate(((this.rotation * Math.PI) / 180) * -1)
        this.ctx.scale(this.scale, this.scale)

        this.updateGradient()

        this.ctx.fillStyle = this.gradient
        this.ctx.beginPath()
        this.ctx.arc(0, 0, this.planetRadius, 0, Math.PI * 2, true)
        this.ctx.fill()

        this.ctx.restore()
      },

      intro: function() {
        this.setGradient()

        gsap.to(this, {
          scale: 1,
          alpha: 1,
          duration: 2,
          delay: 0.2,
          ease: 'power2.inOut',
          onUpdate: this.draw.bind(this),
          onComplete: this.play.bind(this),
        })
      },

      outro: function() {
        gsap.to(this, {
          scale: 0.8,
          alpha: 0,
          duration: 1,
          ease: 'power2.inOut',
          onUpdate: this.draw.bind(this),
          onComplete: () => {
            if (this.timeline) {
              this.timeline.kill()
              this.timeline = null
            }

            this.step = 1
            this.rotation = 40
          },
        })
      },

      play: function() {
        this.timeline = gsap.timeline()

        this.timeline.fromTo(
          this,
          { step: 1 },
          { step: 39, duration: 36, ease: 'none' }
        )

        this.timeline.fromTo(
          this,
          { rotation: 40 },
          { rotation: -140, duration: 45, ease: 'none' },
          '-=36'
        )

        this.timeline.eventCallback('onUpdate', this.draw.bind(this))
        this.timeline.eventCallback('onComplete', this.play.bind(this))
      },

      onResize: function() {
        window.clearInterval(this.intervalId)
        this.intervalId = window.setTimeout(this.setCanvasSize.bind(this), 100)
      },

      init: function(resolve) {
        this.setup()
        resolve()
      },
    }

    planet.init(resolve)
  })
}

export default setupCanvas
