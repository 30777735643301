const emitter = {
  emit: function(event, data) {
    window.dispatchEvent(new CustomEvent(event, { detail: data }))
  },

  listen: function(event, cb) {
    window.addEventListener(
      event,
      evt => {
        cb(evt.detail)
      },
      false
    )
  },
}

export default emitter
