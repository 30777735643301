import Highway from '@dogstudio/highway'
import gsap from 'gsap'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'

const _fastdom = fastdom.extend(fastdomPromised)
const mainWrapper = document.querySelector('.l-main')

class defaultTransition extends Highway.Transition {
  in({ from, to, _, done }) {
    _fastdom
      .mutate(() => {
        from.parentNode.removeChild(from)
      })
      .then(() => {
        gsap.set(to.querySelector('.l-content'), { opacity: 1 })

        const timeline = gsap.timeline()

        timeline.fromTo(
          to,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1,
            delay: 0.4,
            onComplete: () => {
              _fastdom
                .mutate(() => {
                  mainWrapper.style.overflowY = ''
                })
                .then(done)
            },
          }
        )

        if (to.querySelector('.c-cover')) {
          timeline.fromTo(
            to.querySelector('.c-cover__image'),
            {
              scale: 1.025,
            },
            {
              scale: 1,
              duration: 1,
              ease: 'power2.out',
            },
            '-=0.8'
          )
        }
      })
  }

  out({ from, _, done }) {
    _fastdom
      .mutate(() => {
        mainWrapper.style.overflowY = 'hidden'
      })
      .then(() => {
        gsap.to(from, {
          opacity: 0,
          duration: 0.4,
          onComplete: () => {
            window.scrollTo(0, 0)
            done()
          },
        })
      })
  }
}

export default defaultTransition
