const Page = (el) => {
  const page = {
    page: el,
    canvas: null,
    program: null,
    booking: null,
    bookingMobile: null,
    reservation: null,
    accordions: null,
    slideShows: null,
    tabs: null,
    videos: null,
    singles: null,
    forms: null,
    streams: null,
    notice: null,
    cookiebot: null,

    getRefs: function () {
      this.canvas = this.page.querySelector('.c-canvas')
      this.program = this.page.querySelector('.c-program')
      this.booking = this.page.querySelector('.c-booking')
      this.bookingMobile = this.page.querySelector('.c-booking-mobile')
      this.accordions = this.page.querySelectorAll('.c-accordion')
      this.slideShows = this.page.querySelectorAll('.c-slideshow')
      this.tabs = this.page.querySelectorAll('.c-tabs')
      this.videos = this.page.querySelectorAll('.c-video')
      this.singles = this.page.querySelectorAll('.c-single')
      this.forms = this.page.querySelectorAll('.c-form')
      this.reservation = this.page.querySelector('.c-reservation')
      this.streams = this.page.querySelectorAll('.c-stream')
      this.cookiebot = this.page.querySelector('.c-cookiebot')
    },

    setupModules: function () {
      if (this.canvas !== null) {
        import(/* webpackChunkName: "canvas" */ './setupCanvas').then(
          ({ default: setupCanvas }) => {
            setupCanvas(this.canvas)
          }
        )
      }

      if (this.program !== null) {
        import(/* webpackChunkName: "program" */ './setupProgram').then(
          ({ default: setupProgram }) => {
            setupProgram(this.program)
          }
        )
      }

      if (this.booking !== null) {
        import(/* webpackChunkName: "booking" */ './setupBooking').then(
          ({ default: setupBooking }) => {
            setupBooking(this.booking)
          }
        )
      }

      if (this.bookingMobile !== null) {
        import(
          /* webpackChunkName: "bookingMobile" */ './setupBookingMobile'
        ).then(({ default: setupBookingMobile }) => {
          setupBookingMobile(this.bookingMobile)
        })
      }

      if (this.reservation !== null) {
        import(/* webpackChunkName: "reservation" */ './setupReservation').then(
          ({ default: setupReservation }) => {
            setupReservation(this.reservation)
          }
        )
      }

      if (this.accordions !== null && this.accordions.length > 0) {
        import(/* webpackChunkName: "accordions" */ './setupAccordion').then(
          ({ default: setupAccordion }) => {
            this.accordions.forEach((module) => {
              setupAccordion(module)
            })
          }
        )
      }

      if (this.slideShows !== null && this.slideShows.length > 0) {
        import(/* webpackChunkName: "slideshows" */ './setupSlideshow').then(
          ({ default: setupSlideshow }) => {
            this.slideShows.forEach((module) => {
              setupSlideshow(module)
            })
          }
        )
      }

      if (this.tabs !== null && this.tabs.length > 0) {
        import(/* webpackChunkName: "tabs" */ './setupTabs').then(
          ({ default: setupTabs }) => {
            this.tabs.forEach((module) => {
              setupTabs(module)
            })
          }
        )
      }

      if (this.videos !== null && this.videos.length > 0) {
        import(/* webpackChunkName: "tabs" */ './setupVideo').then(
          ({ default: setupVideo }) => {
            this.videos.forEach((module) => {
              setupVideo(module)
            })
          }
        )
      }

      if (this.singles !== null && this.singles.length > 0) {
        import(/* webpackChunkName: "tabs" */ './setupSingle').then(
          ({ default: setupSingle }) => {
            this.singles.forEach((module) => {
              setupSingle(module)
            })
          }
        )
      }

      if (this.forms !== null && this.forms.length > 0) {
        import(/* webpackChunkName: "forms" */ './setupForm').then(
          ({ default: setupForm }) => {
            this.forms.forEach((module) => {
              setupForm(module)
            })
          }
        )
      }

      if (this.streams !== null && this.streams.length > 0) {
        import(/* webpackChunkName: "stream" */ './setupStream').then(
          ({ default: setupStream }) => {
            this.streams.forEach((module) => {
              setupStream(module)
            })
          }
        )
      }

      if (this.cookiebot !== null) {
        import(/* webpackChunkName: "cookiebot" */ './setupCookiebot').then(
          ({ default: setupCookiebot }) => {
            setupCookiebot(this.cookiebot)
          }
        )
      }
    },

    destroy() {},

    init: function () {
      this.getRefs()
      this.setupModules()
    },
  }

  return page
}

export default Page
